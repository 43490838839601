class DateFormat {
    from_display_to_mysql (str) {
        let pieces = str.split(" ")
        let pieces_of_pieces = pieces[0].split(".")
        str = pieces_of_pieces[2] + "-" + pieces_of_pieces[1] + "-" + pieces_of_pieces[0] + " " + pieces[1]
        return str
    }

    from_display_to_js (str) {
        let pieces = str.split(" ")
        let pieces_of_pieces = pieces[0].split(".")
        str = pieces_of_pieces[2] + "/" + pieces_of_pieces[1] + "/" + pieces_of_pieces[0] + " " + pieces[1]
        return str
    }

    get_display_now () {
        let date = new Date()
        var invdate = new Date(date.toLocaleString('he-IL', {
            timeZone: "Asia/Jerusalem"
        }))
        let date_str = invdate.toLocaleDateString()
        let date_arr = date_str.split("/")
        date_str = date_arr[1] + "." + date_arr[0] + "." + date_arr[2]
        let ret_str = date_str + " "
        let arr = invdate.toLocaleTimeString().split(":")
        arr[0] = invdate.getHours()
        
        ret_str += arr[0] + ":" +  arr[1]
        return ret_str
    }

    validate_st_str (str) {
        let arr_dt = str.split(" ")
        if (arr_dt.length != 2) {
            return false
        }
        let arr_d = arr_dt[0].split(".")
        
        if (arr_d.length != 3) {
            return false
        }
        let arr_t = arr_dt[1].split(":")
        if (arr_t.length != 2) {
            return false
        }
        str = this.from_display_to_js(str)
        var d = new Date(str)
        if (isNaN(d.getTime())) {
            return false
        }
        return true
    }
}

module.exports = DateFormat