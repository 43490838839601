<template>
  <BackofficeBase :loader_prop="loader_prop">
    <h1>WYSIWYG</h1>
    <div id = "hp_1">

    </div>
    <button v-on:click="save">Save</button>
    <button v-on:click="load">Load</button>

  </BackofficeBase>
</template>


<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel"
import Dateformat from '../../utils/DateFormat';
import axios from 'axios';

export default {
  name: 'Backoffice',
  data() {
    return {
      loader_prop : true,
      message: {
        time: '',
        text: ''
      }
    }
  },
  async mounted() {
    const utils = new BackendModel()
    let kvs_data = await axios.post(utils.getBaseUrl() + "/Api/service/backoffice/get_wysiwyg", {})
    await window.setWysiwyg(kvs_data.data.data)
    this.loader_prop = false
  },
  components: {
    BackofficeBase
  },
  methods: {
    save: async function() {
      const date = new Dateformat()
      this.message.time = date.from_display_to_mysql(date.get_display_now())
      this.message.text = window.hp_1.root.innerHTML
      const utils = new BackendModel()
      await axios.post(utils.getBaseUrl() + "/Api/service/backoffice/save_wysiwyg", {"text" : this.message.text, "time" : this.message.time})
    },
    load: function() {
      window.hp_1.root.innerHTML = this.message.text;
    }
  }
}
</script>